<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Assignment
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs2>
                                <v-calendar-field
                                        id-val="frmDate"
                                        v-model="frmDate"
                                        label="From Date">
                                </v-calendar-field>
                            </v-flex>
                            <v-flex xs2>
                                <v-calendar-field
                                        id-val="toDate"
                                        v-model="toDate"
                                        label="To Date">
                                </v-calendar-field>
                            </v-flex>

                            <v-flex xs3>
                                <v-select :items="subjectResponse" :disabled="subjectResponse.length<1" class="pa-0"
                                          label="Subject"
                                          :loading="subjectLoading"
                                          v-model="filter_subject" outlined dense/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table :headers="headers" :items="form.items.data"
                                  :options.sync="pagination" :server-items-length="form.items.meta.total"
                                  :loading="form.loading">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td>
                                    <span v-if="item.documents.length > 0">
                                        <span v-for="(x ,i) in item.documents"
                                              :key="i">
                                            <a target="_blank" :href="x.docs"
                                               style="text-decoration: none">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on">attach_file</v-icon>
                                                    </template>
                                                    <span>Click to download doc.</span>
                                                </v-tooltip>
                                            </a>
                                            </span>
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                                <td class="text-xs-left">{{ item.title }} <br>
                                    <small style="color:#777;">{{item.description}}</small>
                                </td>
                                <td class="text-xs-left">{{ item.subject ? (item.subject.name ?
                                    item.subject.name.toUpperCase():'-'):'-' }}
                                </td>
                                <td class="text-xs-left">{{ item.teacher ? (item.teacher.name ?
                                    item.teacher.name :'-'):'-' }}
                                </td>
                                <td class="text-xs-left">
                                    <v-chip small label color="primary" text-color="white">
                                        {{ item.assignment_date }}
                                    </v-chip>
                                    <v-chip small label color="orange" text-color="white">
                                        {{ item.submission_date }}
                                    </v-chip>
                                </td>
                                <td class="text-xs-right">
                                    <!--<view-button v-if="today >= item.submission_date"-->
                                                 <!--permission="assignment-update&assignment-read"-->
                                                 <!--@click.native="viewAssignemnt(item.id)"/>-->

                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer/>
                        <v-pagination v-if="form.items.data.length>=10" v-model="pagination.page"
                                      :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent scrollable max-width="600px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Assignment Details</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                v-model="valid"
                                :lazy-validation="lazy"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save">
                            <v-container grid-list-md>
                                <v-layout row wrap pt-2>
                                    <v-flex xs12 sm6>
                                        <v-autocomplete
                                                :items="filter_subjects" required class="pa-0" label="Subject*"
                                                name="subject_id" v-model="form.subject_id"
                                                :error-messages="form.errors.get('subject_id')" outlined dense
                                                :rules="[v => !!v || 'SUbject is required']"
                                        />
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-autocomplete
                                                class="pa-0"
                                                v-model="form.teacher_id"
                                                :items="items"
                                                :loading="isLoading"
                                                :search-input.sync="search"
                                                hide-no-data
                                                persistent-hint
                                                :hint="getSelectedTeacher.primary_mobile || getSelectedTeacher.email"
                                                item-text="name"
                                                item-value="id"
                                                :error-messages="form.errors.get('teacher_id')"
                                                label="Teacher" outlined dense
                                                :rules="[v => !!v || 'Teacher is required']"
                                        ></v-autocomplete>
                                    </v-flex>

                                    <v-flex xs12 sm6 pt-3>
                                        <v-calendar-field
                                                id-val="assignment_date"
                                                v-model="form.assignment_date"
                                                label="Assignment Date"
                                                :rules="[v => !!v || 'Assignment is required']">
                                        </v-calendar-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 pt-3>
                                        <v-calendar-field
                                                id-val="submission_date"
                                                v-model="form.submission_date"
                                                label="Submission Date"
                                                :rules="[v => !!v || 'Submission Date is required']">

                                        </v-calendar-field>
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-text-field autocomplete="off" label="Assignment title*" required
                                                      class="pa-0"
                                                      v-model="form.title" name="title"
                                                      :error-messages="form.errors.get('title')" outlined dense
                                                      :rules="[v => !!v || 'Assignemnt Title is required']"/>
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-textarea name="description" class="pa-0" label="Description"
                                                    v-model="form.description" outlined dense
                                                    :rules="[v => !!v || 'Description is required']"/>
                                    </v-flex>

                                    <v-flex>
                                        <v-file-input ref="files" outlined dense :clearable="false"
                                                      v-on:change="handleFilesUpload"
                                                      multiple label="Add Files">
                                            <template v-slot:selection="{ text}">

                                                <v-chip
                                                        small
                                                        label
                                                        color="primary">
                                                    {{ text }}
                                                </v-chip>
                                            </template>

                                        </v-file-input>
                                    </v-flex>

                                    <v-uploaded-showcase :documents="multipleFiles"></v-uploaded-showcase>


                                    <!--<input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>-->
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Pagination from '@/library/Mixins'
    import ImageCompressor from 'image-compressor.js'
    import {validateDate} from "../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    let today = new Date();
    let tomorrow = today.setDate(today.getDate() + 1);

    export default {
        mixins: [Pagination],
        data: () => ({
            valid: true,
            lazy: false,
            isLoading: false,
            form: new Form({
                title: '',
                description: '',
                document: '',
                assignment_id: '',
                section_id: '',
                teacher_id: '',
                subject_id: '',
                submission_date: new NepaliDate(new Date(tomorrow)).format('YYYY-MM-DD'),
                assignment_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),
                status: 0,
            }, '/api/assignment'),
            search: null,
            multipleFiles: [],
            filter_subject: '',
            pagination: {
                itemsPerPage: 25
            },
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            headers: [
                {text: '#', align: 'left', value: 'id', width: 20, sortable: false},
                {text: 'Doc.', align: 'left', value: 'id', width: 10, sortable: false},
                {text: 'Title', align: 'left', value: 'title', width: 300, sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false, width: 170},
                {text: 'Assignment/Submission Date', align: 'left', value: 'assignment_date'},
                // {text: 'Status', align: 'left', value: 'status', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            sections: [],
            grades: [],
            grade: '',
            section: '',
            subjects: [],
            filter_subjects: [],
            subjectLoading: false,
            frmDate: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            toDate: new NepaliDate(new Date(tomorrow)).format('YYYY-MM-DD'),
            teachers: [],
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            today: new NepaliDate(new Date()).format('YYYY-MM-DD'),
            assignmentSubjects: [],
            subjectResponse: []
        }),
        computed: {
            ...mapState(['batch', 'guardian']),
            items() {
                return this.teachers.map(entry => {
                    return Object.assign({}, entry, {name: entry.name})
                })
            },
            getSelectedTeacher() {
                let teacher = {};
                if (this.teachers.length) {
                    let $this = this;
                    this.teachers.map(function (data) {
                        if ($this.form.teacher_id === data.id) {
                            teacher = data;
                        } else if ($this.update) {
                            teacher = data;
                        }
                    });
                }
                return teacher;
            }
        },
        mounted() {
            this.getGrades();
            const student = JSON.parse(this.$storage.get('_s_ch'));
            this.grade = student.grade_id;
            this.section = student.section_id;
        },
        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            'grade': function () {
                this.getSections();
            },
            'section': function () {
                this.subjectLoading = true;
                let $this = this;
                this.get().then(function () {
                    $this.getSubjects();
                });
            },
            'filter_subject': function () {
                this.get();
            },
            'frmDate': function () {
                this.get();
            },
            'toDate': function () {
                this.get();
            },
            search(val) {
                if (!val) return;
                if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=teacher&search=' + val)
                    .then(({data}) => {
                        this.teachers = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
            'form.subject_id': function (value) {
                if (value) {
                    let teachers = {};
                    this.subjectResponse.map((sub) => {
                        if (sub.value === value) {
                            teachers = sub.teachers;
                        }
                    });
                    if (teachers) {
                        this.teachers = teachers.map(res => {
                            return {
                                name: res.name,
                                primary_mobile: res.mobile,
                                email: '',
                                occupation: '',
                                gender: '',
                                id: res.teacher_id
                            }

                        });
                        if (this.teachers.length === 1) {
                            this.form.teacher_id = this.teachers[0].id
                        }
                    }

                }
            }
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&sectionId=' + this.section.toString() + '&frmDate=' + this.frmDate.dateForm() + '&toDate=' + this.toDate.dateForm() + '&subId=' + this.filter_subject.toString() || '0';
            },
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            get(params) {
                if (this.section && this.frmDate && this.toDate) {
                    let selectedSubjects = [];
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    return this.form.get(null, query).then(({data}) => {
                        data.data.map(function (item) {
                            selectedSubjects.push(item.subject_id);
                        });
                        this.assignmentSubjects = selectedSubjects;
                        this.pagination.totalItems = data.meta.total
                    })
                }
            },
            save() {
                const formData = new FormData();
                Object.keys(this.form.originalData).map((keys) => {
                    formData.append(keys, this.form.originalData[keys]);
                });

                Array.from(this.multipleFiles).forEach((file) => {
                    formData.append('document[]', file);
                });

                formData.append('title', this.form.title);
                formData.append('description', this.form.description);
                formData.append('section_id', this.section);
                formData.append('teacher_id', this.form.teacher_id);
                formData.append('subject_id', this.form.subject_id);
                formData.append('status', this.form.status);
                formData.append('assignment_date', this.form.assignment_date.dateForm());
                formData.append('submission_date', this.form.submission_date.dateForm());
                let subjectId = this.form.subject_id;

                this.$rest.post('/api/assignment', formData).then((res) => {
                    this.$events.fire('notification', {message: res.data.message, status: 'success'});
                    this.assignmentSubjects.push(subjectId);
                    this.get();
                    this.populateSubjects();
                });
            },
            getGrades() {
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&roleWise=true').then(({data}) => {

                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            getSubjects() {
                this.filter_subjects = [];
                this.$rest.get('/api/subject?rowsPerPage=50&grade=' + this.grade + '&section=' + this.section).then(({data}) => {
                    this.subjectLoading = false;
                    this.subjectResponse.push({value: '', text: 'All'});
                    let $this = this;
                    data.data.map(function (item) {
                        $this.subjectResponse.push({
                            value: item.id,
                            text: item.name.toUpperCase(),
                            teachers: item.teachers
                        });
                    });
                    this.populateSubjects();
                })
            },

            populateSubjects() {
                let $this = this;
                this.filter_subjects = [];
                this.subjectResponse.map((item) => {
                    if (!$this.assignmentSubjects.includes(item.value)) {
                        if (item.value !== '') {
                            $this.filter_subjects.push({value: item.value, text: item.text});
                        }
                    }
                });

                if (this.form.subject_id) {
                    $this.filter_subjects.push({value: $this.form.subject.id, text: $this.form.subject.name})
                }
            },

            getSections() {
                this.filter_subjects = [];
                this.sections = [];
                if (this.grade)
                    this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                        this.sections = data.data.map(item => {
                            return {value: item.id, text: item.name}
                        });
                    })
            },
            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    this.$store.dispatch('notifyError', "Please upload a valid image file!");
                    return
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener('load', () => {
                    this.imagePicker.url = fileReader.result;
                    this.imagePicker.name = fileName;

                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(files[0], {quality: .7})
                        .then((result) => {
                            this.imagePicker.image = result
                        })
                        .catch((err) => {
                            // Handle the error
                        })
                })
            },
            resetImagePicker() {
                this.imagePicker = {
                    url: undefined,
                    image: undefined,
                    name: undefined
                };
                this.$refs.image.value = "";
            },
            onPickFile() {
                this.$refs.image.click()
            },
            viewAssignemnt(id) {
                this.$router.push({
                    name: 'assignment-detail',
                    params: {
                        assignmentId: id
                    }
                })
            },
            updateAssignment(item) {
                this.form.edit(item);
                this.form.day = item.routine_day;
                if (Object.keys(item.teacher).length > 0) {
                    this.teachers = [{
                        name: item.teacher.name,
                        Name: item.teacher.name,
                        email: item.teacher.email,
                        id: item.teacher.id
                    }];
                }
                this.getSubjects();
                this.update = true;
            },
            handleFilesUpload(files) {
                this.multipleFiles = files;
            }

        }
    }
</script>
<style lang="scss" scoped></style>